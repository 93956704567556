function geraAnos() {
    const anoAtual = new Date().getFullYear();
    const anos = [];

    for (let i = 4; i >= -1; i--) {
        anos.push(anoAtual - i);
    }

    return anos;
}

export default geraAnos;