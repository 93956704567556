import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  Radio,
  Dimmer,
  Form,
  Header,
  Loader,
  Pagination,
  Segment,
  Table,
  Button,
  Icon,
  Grid,
} from 'semantic-ui-react';
import useApiPangeo from '../../hooks/useApiPangeo';
import GraficoPizza from '../../components/GraficoPizza';
import geraAnos from '../../utils/gera-anos';

function encodeValor(anyVal) {
  if (Array.isArray(anyVal)) {
    return anyVal.join(',');
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => `${k}=${encodeValor(object[k])}`)
    .join('&');
}

function AcordosPlrPorRegional() {
  const { search } = useLocation();
  const { data, isLoading, getAcordoPlrPorRegional } = useApiPangeo();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [anoAtual, setAnoAtual] = useState(new Date().getFullYear());
  const [tipoApuracao, setTipoApuracao] = useState('vigencia');

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const ano = searchParams.get('ano');
    const tpApuracao = searchParams.get('tipoApuracao');
    if (ano) {
      setAnoAtual(ano);
    }
    if (tpApuracao) {
      setTipoApuracao(tpApuracao);
    }
    if (tpApuracao && ano) {
      getAcordoPlrPorRegional(ano, tpApuracao);
    }
  }, [getAcordoPlrPorRegional, page, search]);

  const changeFiltro = (searchNovo = null) => {
    navigate({
      pathname: '/acordos-plr',
      search: `?${searchNovo || ''}`,
    });
  };

  const changeAnoFiltro = (e, data) => {
    const { value } = data;

    changeFiltro(encodeUrl({ ano: value, tipoApuracao: tipoApuracao }));
  };

  const changeTipoApuracaoFiltro = (e, data) => {
    const { value } = data;

    changeFiltro(encodeUrl({ tipoApuracao: value, ano: anoAtual }));
  };

  const irParaAcordos = (idLocaacor) => {
    navigate(`/acordos-plr/regional/${idLocaacor}?ano=${anoAtual}&tipoApuracao=${tipoApuracao}`);
  }

  const optionsApuracao = [
    {key: 'a', text: 'por data da assinatura', value: 'assinatura'},
    {key: 'v', text: 'por início da vigência', value: 'vigencia'}
  ];

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: 'left' }}>
          Acordos de PLR por regional
        </Header>
        <Breadcrumb style={{ float: 'right' }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Acordos de PLR por regional</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form>
        <Form.Group>
          <Form.Input label="Ano">
            <div className="inline-checkbox">
              {geraAnos().map((ano) => (
                <Radio
                  label={ano}
                  value={ano}
                  key={ano}
                  checked={parseInt(anoAtual) === ano}
                  onChange={changeAnoFiltro}
                />
              ))}
            </div>
          </Form.Input>
        </Form.Group>
      </Form>

      <Grid>
        <Grid.Row stretched>
          <Grid.Column mobile={16} computer={8}>
            <Segment>
              <Dimmer active={isLoading} inverted>
                <Loader>Aguarde ...</Loader>
              </Dimmer>
              <Table unstackable celled collapsing className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Regional</Table.HeaderCell>
                    <Table.HeaderCell>Acordos</Table.HeaderCell>
                    <Table.HeaderCell>Empresas</Table.HeaderCell>
                    <Table.HeaderCell>Trabalhadores abrangidos</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {data?.['acordos-plr-por-regional']?.map((regional) => (
                    <Table.Row key={`${regional?.id}`}>
                      <Table.Cell data-label="Regional">
                        <Header as="h4" image>
                          <Header.Content>{regional?.locaacor}</Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell data-label="Acordos">{new Intl.NumberFormat().format(regional?.quantidade || 0)}</Table.Cell>
                      <Table.Cell data-label="Empresas">{new Intl.NumberFormat().format(regional?.total_unidades || 0)}</Table.Cell>
                      <Table.Cell data-label="Trabalhadores">{new Intl.NumberFormat().format(regional?.num_trabalhadores_abrangidos || 0)}</Table.Cell>
                      <Table.Cell>
                          <Button
                              basic
                              style={{ boxShadow: 'none', paddingLeft: '5px', paddingRight: '0' }}
                              onClick={() => irParaAcordos(regional.id)}
                          >
                              Ver acordos
                              <Icon name="arrow circle right" />
                          </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                activePage={page}
                style={{ marginTop: '1em' }}
                onPageChange={(e, { activePage }) => setPage(activePage)}
                totalPages={(data && "page_count" in data) ? data.page_count : 0}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Segment>
              <GraficoPizza 
                isLoading={false} 
                keys={data?.["acordos-plr-por-regional"]?.map((item) => item.locaacor)} 
                values={data?.["acordos-plr-por-regional"]?.map((item) => item.total_unidades)}
                titulo="Quantidade de empresas por regional" />
              <br /><br />
              <GraficoPizza 
                isLoading={false} 
                keys={data?.["acordos-plr-por-regional"]?.map((item) => item.locaacor)} 
                values={data?.["acordos-plr-por-regional"]?.map((item) => item.total_trabalhadores)}
                titulo="Quantidade de trabalhadores por regional" />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default AcordosPlrPorRegional;
