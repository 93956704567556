import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Radio,
  Dimmer,
  Form,
  Header,
  Loader,
  Pagination,
  Segment,
  Table,
  Input,
} from "semantic-ui-react";
import useApiPangeo from "../../hooks/useApiPangeo";
import Moment from "react-moment";
import MembrosModal from "./components/MembrosModal";

function encodeValor(anyVal) {
  if (Array.isArray(anyVal)) {
    return anyVal.join(",");
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => `${k}=${encodeValor(object[k])}`)
    .join("&");
}

function Cipas() {
  const { search } = useLocation();
  const { data, isLoading, getCipa } = useApiPangeo();
  const [filtroNomeEmpresa, setFiltroNomeEmpresa] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [filtro, setFiltro] = useState("ativas");

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setFiltro(searchParams.get("regional") || "");

    let filtros = {
      regional: searchParams.get("regional"),
      nomeEmpresa: filtroNomeEmpresa,
    };

    filtros["limit"] = 10;
    filtros["page"] = page;

    getCipa(filtros);
  }, [getCipa, page, search, filtroNomeEmpresa]);

  const changeFiltro = (searchNovo = null) => {
    navigate({
      pathname: `/cipas`,
      search: `?${searchNovo || ""}`,
    });
  };

  const changeRegional = (e, data) => {
    const { value } = data;

    changeFiltro(encodeUrl({ regional: value }));
  };

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: "left" }}>
          CIPAs
        </Header>
        <Breadcrumb style={{ float: "right" }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>CIPAs</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form>
        <Form.Group>
          <Form.Input label="Regional">
            <div className="inline-checkbox">
              <Radio
                label="TODAS"
                value={0}
                key={0}
                checked={!filtro || parseInt(filtro) === 0}
                onChange={changeRegional}
              />
              <Radio
                label="GUARULHOS/SP"
                value={2}
                key={2}
                checked={parseInt(filtro) === 2}
                onChange={changeRegional}
              />
              <Radio
                label="SAO MIGUEL"
                value={5}
                key={5}
                checked={parseInt(filtro) === 5}
                onChange={changeRegional}
              />
              <Radio
                label="STO ANDRE"
                value={3}
                key={3}
                checked={parseInt(filtro) === 3}
                onChange={changeRegional}
              />
            </div>
          </Form.Input>
          <Form.Input label="Nome da empresa">
            <Input icon="search" placeholder="Buscar..." onChange={(e) => setFiltroNomeEmpresa(e.target.value)} />
          </Form.Input>
        </Form.Group>
      </Form>

      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <Table unstackable celled collapsing className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Unidade</Table.HeaderCell>
              <Table.HeaderCell>Período</Table.HeaderCell>
              <Table.HeaderCell>Membros</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data?._embedded?.["peju-unid-orga"]?.map((cipa) => (
              <Table.Row key={`${cipa?.peju}_${cipa?.seq}`}>
                <Table.Cell data-label="Unidade">
                  <Header as="h4" image>
                    <Header.Content>
                      {cipa?._embedded?.unidade?.nome}
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell data-label="Período">
                  <Moment format="DD/MM/YYYY" date={cipa?.dtinic?.date} />
                  {" - "}
                  <Moment format="DD/MM/YYYY" date={cipa?.dtfim?.date} />
                </Table.Cell>
                <Table.Cell>
                    <MembrosModal membros={cipa?._embedded?.membros} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                Total de CIPAs:{" "}
                {new Intl.NumberFormat().format(data?.total_items || 0)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Pagination
          activePage={page}
          style={{ marginTop: "1em" }}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          totalPages={(data && "page_count" in data) ? data["page_count"] : 0}
        />
      </Segment>
    </div>
  );
}

export default Cipas;
