import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Radio,
  Dimmer,
  Form,
  Header,
  Loader,
  Pagination,
  Segment,
  Table,
  Button,
  Icon,
  Grid,
} from "semantic-ui-react";
import useApiPangeo from "../../hooks/useApiPangeo";
import GraficoPizza from "../../components/GraficoPizza";
import geraAnos from "../../utils/gera-anos";

function encodeValor(anyVal) {
  if (Array.isArray(anyVal)) {
    return anyVal.join(",");
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => `${k}=${encodeValor(object[k])}`)
    .join("&");
}

function AcordosPorRegional() {
  const { search } = useLocation();
  const { data, isLoading, getAcordoPorRegional } = useApiPangeo();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [anoAtual, setAnoAtual] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const ano = searchParams.get("ano");
    setAnoAtual(ano || "");
    getAcordoPorRegional(ano);
  }, [getAcordoPorRegional, page, search]);

  const changeFiltro = (searchNovo = null) => {
    navigate({
      pathname: "/acordos",
      search: `?${searchNovo || ""}`,
    });
  };

  const changeAnoFiltro = (e, data) => {
    const { value } = data;

    changeFiltro(encodeUrl({ ano: value }));
  };

  const irParaAcordos = (idLocaacor) => {
    navigate(`/acordos/regional/${idLocaacor}?ano=${anoAtual}`);
  };

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: "left" }}>
          Acordos diversos por regional
        </Header>
        <Breadcrumb style={{ float: "right" }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            Acordos diversos por regional
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form>
        <Form.Group>
          <Form.Input label="Ano">
            <div className="inline-checkbox">
              {geraAnos().map((ano) => (
                <Radio
                  label={ano}
                  value={ano}
                  key={ano}
                  checked={parseInt(anoAtual) === ano}
                  onChange={changeAnoFiltro}
                />
              ))}
            </div>
          </Form.Input>
        </Form.Group>
      </Form>
      <Grid>
        <Grid.Row stretched>
          <Grid.Column mobile={16} computer={8}>
            <Segment>
              <Dimmer active={isLoading} inverted>
                <Loader>Aguarde ...</Loader>
              </Dimmer>
              <Table unstackable celled collapsing className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Regional</Table.HeaderCell>
                    <Table.HeaderCell>Acordos</Table.HeaderCell>
                    <Table.HeaderCell>Empresas</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {data?.["acordos-por-regional"]?.map((regional) => (
                    <Table.Row key={`${regional?.id}`}>
                      <Table.Cell data-label="Regional">
                        <Header as="h4" image>
                          <Header.Content>{regional?.locaacor}</Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell data-label="Acordos">
                        {new Intl.NumberFormat().format(
                          regional?.quantidade || 0
                        )}
                      </Table.Cell>
                      <Table.Cell data-label="Empresas">
                        {new Intl.NumberFormat().format(
                          regional?.total_empresas || 0
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          basic
                          style={{
                            boxShadow: "none",
                            paddingLeft: "5px",
                            paddingRight: "0",
                          }}
                          onClick={() => irParaAcordos(regional.id)}
                        >
                          Ver acordos
                          <Icon name="arrow circle right" />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                activePage={page}
                style={{ marginTop: "1em" }}
                onPageChange={(e, { activePage }) => setPage(activePage)}
                totalPages={(data && "page_count" in data) ? data.page_count : 0}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Segment>
              <GraficoPizza 
                isLoading={false} 
                keys={data?.["acordos-por-regional"]?.map((item) => item.locaacor)} 
                values={data?.["acordos-por-regional"]?.map((item) => item.total_empresas)} 
                titulo="Quantidade de empresas por regional" />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default AcordosPorRegional;
