import {
  Grid, Segment, Header, Button,
} from 'semantic-ui-react';

import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GraficoMovimentacaoSocios from './components/GraficoMovimentacaoSocios';
import BarraNumeros from './components/BarraNumeros';
import GraficoCipasPorRegional from './components/GraficoCipasPorRegional';
import GraficoAcordosPlrAno from './components/GraficoAcordosPlrAno';
import GraficoAcordosAno from './components/GraficoAcordosAno';

const ANO_ATUAL = new Date().getFullYear();

function Dashboard() {
  const navigate = useNavigate();
  const usuario = useSelector((state) => state.login.usuario);

  const irParaManuais = () => navigate('/manuais');

  const irParaFinanceiro = () => navigate('/financeiro');

  const irParaPangeo = () => window.location.replace(
    'https://sist.sintrabor.org.br/pangeo',
  );

  const irParaCi = () => window.location.replace(
    'https://sist.sintrabor.org.br/den',
  );

  const vePangeo = () => {
    return usuario?.demais_dados?.recursos
      .filter((recurso) => recurso.split(".")[0] === "pangeo").length > 0
  };

  const veDen = () => {
    return usuario?.demais_dados?.recursos
      .filter((recurso) => recurso.split(".")[0] === "denBgp").length > 0
  };

  const veTodosGraficos = () => {
    return usuario?.demais_dados?.recursos
      .filter((recurso) => recurso.split(".")[0] === "intranetBgp").length > 0
  }

  return (
    <>
      <Header as="h3">Dashboard</Header>

      <Grid columns="equal">
        {vePangeo() ? <BarraNumeros /> : null}
        <div className="menu-sistemas">
          <span className="header-menu">Ir para: </span>
          {vePangeo() ? (
            <Button basic onClick={irParaPangeo}>
              <img style={{ width: '30px' }} src="logo_pangeo.png" alt="logo_pangeo" />
              <br />
              Pangeo
            </Button>
          ) : null}

          {veDen() ? (
            <Button basic onClick={irParaCi}>
              <img style={{ width: '30px' }} src="logo_ci.png" alt="logo_ci" />
              <br />
              Comunicação Interna
            </Button>
          ) : null}

          {veTodosGraficos() && false ? <Button basic onClick={irParaFinanceiro}>
            <img style={{ width: '30px' }} src="logo_financeiro.png" alt="logo_financeiro" />
            <br />
            Painel Financeiro
          </Button> : null}

          <Button basic onClick={irParaManuais}>
            <img style={{ width: '30px' }} src="logo_manuais.png" alt="logo_manuais" />
            <br />
            Manuais e Procedimentos
          </Button>
        </div>

        {veTodosGraficos() ? (
          <Grid.Row stretched className="grupo-graficos">
            <Grid.Column mobile={16} tablet={8} computer={3}>
              <Segment>
                <Link
                  to="/cipas?filtro=ativas"
                  className="ui green right corner label"
                >
                  <i className="plus icon" />
                </Link>
                <GraficoCipasPorRegional />
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <Segment>
                <Link
                  to={`/acordos-plr?ano=${ANO_ATUAL}&tipoApuracao=vigencia`}
                  className="ui green right corner label"
                >
                  <i className="plus icon" />
                </Link>
                <GraficoAcordosPlrAno />
              </Segment>
              <Segment>
                <Link
                  to={`/acordos?ano=${ANO_ATUAL}`}
                  className="ui green right corner label"
                >
                  <i className="plus icon" />
                </Link>
                <GraficoAcordosAno />
              </Segment>
            </Grid.Column>
            <Grid.Column tablet={8} mobile={16} computer={8}>
              <Segment>
                <GraficoMovimentacaoSocios />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    </>
  );
}

export default Dashboard;
