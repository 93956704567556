import { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Accordion, Dimmer, Loader, Pagination, Segment, Grid, Input } from "semantic-ui-react";
import StatusVisitaText from "../../components/StatusVisitaText";
import useApiPangeo from "../../hooks/useApiPangeo";

function ListaVisitas({ subregi, statusList }) {
  const { data, isLoading, getVisitaList } = useApiPangeo();
  const [page, setPage] = useState(1);
  const [filtroNomeEmpresa, setFiltroNomeEmpresa] = useState(null);

  useEffect(() => {
    const [regi, subr] = subregi.split(".");
    let filtros = {
      "orderBy[row.dtHrRealizacao]": "desc",
      "orderBy[row.dtHrAgendamento]": "desc",
      regi: regi,
      subregi: subr,
      mesUnico: moment().format("YYYY-MM"),
    };

    statusList.split(",").forEach((status, key) => {
      filtros[`status[${key}]`] = status;
    });

    if (filtroNomeEmpresa && filtroNomeEmpresa.length > 0) {
      filtros["texto"] = filtroNomeEmpresa
    }

    filtros["limit"] = 10;
    filtros["page"] = page;


    getVisitaList(filtros);
  }, [getVisitaList, filtroNomeEmpresa, subregi, statusList, page]);

  const formatPanels = (visitas) =>
    visitas?.map((visita) => ({
      key: `panel-${visita?.pdvi}`,
      title: {
        content: (
          <>
            <span style={{ color: "#333" }}>
              {visita?._embedded?.unidade?.nome}
            </span>

            <div
              className="clearfix"
              style={{
                paddingBottom: "1rem",
                paddingTop: "10px",
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "#333",
              }}
            >
              <div style={{ float: "left", width: "60%" }}>
                <Moment
                  format="DD/MM/YYYY HH:mm"
                  date={
                    visita?.dtHrRealizacao?.date ||
                    visita?.dtHrAgendamento?.date
                  }
                />
                <div>{visita?.titulo}</div>
              </div>
              <div style={{ float: "right", width: "40%", textAlign: "right" }}>
                <StatusVisitaText statusChar={visita?.status} asLabel={true} />
              </div>
            </div>
          </>
        ),
      },
      content: {
        content: (
          <>
            <dl style={{ margin: "0" }}>
              <dt>Data</dt>
              <dd>
                <Moment
                  format="DD/MM/YYYY HH:mm"
                  date={
                    visita?.dtHrRealizacao?.date ||
                    visita?.dtHrAgendamento?.date
                  }
                />
              </dd>
              <dt>Visitadores</dt>
              <dd>{visita?._embedded?.visitadores?.map((v) => `${v.nome.trim()}. `)}</dd>
              <dt>Objetivos</dt>
              <dd>{visita?._embedded?.objetivos?.map((v) => `${v.descricao.trim()}. `)}</dd>
              <dt>Descrição</dt>
              <dd style={{ whiteSpace: "break-spaces" }}>
                {visita?.andamento}
              </dd>
            </dl>
          </>
        ),
      },
    }));

  return (
    <div>
      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <div className="clearfix" style={{ marginBottom: "10px" }}>
          <h3 style={{ float: 'left' }}>Visitas da regional</h3>
          <div style={{ float: 'right' }}>
            <Input icon="search" placeholder="Buscar empresa/título..." onChange={(e) => setFiltroNomeEmpresa(e.target.value)} />
          </div>
        </div>
        <Accordion
          style={{ width: "100%" }}
          styled
          panels={formatPanels(data?._embedded?.visita)}
        />
        <Pagination
          activePage={page}
          style={{marginTop: "1em"}}
          onPageChange={(e, {activePage}) => setPage(activePage)}
          totalPages={(data && "page_count" in data) ? data['page_count'] : 0}
        />
      </Segment>
    </div>
  );
}

export default ListaVisitas;
